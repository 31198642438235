/* eslint-disable import/no-anonymous-default-export */
import update from 'immutability-helper';
import { SET_DATA_REGISTER, SET_FORGOT_EMAIL, SET_REGISTER_VALIDATE } from '../actions/actionType';

const initialState = {
  data: {
    Fullname: '',
    email: '',
    Username: '',
    website: '',
    instagram: '',
    phone: '',
    PackageType: '',
  },
  isLoading: false,
  error: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_DATA_REGISTER:
      return update(state, {
        data: { $set: payload },
      });
    case SET_FORGOT_EMAIL:
      return update(state, {
        data: { $set: payload },
      });
    case SET_REGISTER_VALIDATE:
      return update(state, {
        data: {
          email: { $set: payload },
        },
      });

    default:
      return state;
  }
};
